import * as React from 'react';
import { Link, graphql } from 'gatsby';
import {
  Layout,
  SEO,
  CJumbotron,
  CBreadCrumb,
  LWrap,
  CBtnList,
  CFixedImg,
  LContact,
} from '../../../../components/_index';
import '../../../../assets/_sass/page/access.scss';

// markup
const SubPage = ({ data }: { data: any }) => {
  const frontmatter = data?.markdownRemark?.frontmatter;

  return (
    <Layout>
      <SEO title={frontmatter?.title} description={frontmatter?.description} />
      <CJumbotron
        size="small"
        data={{
          title: {
            main: 'ACCESS',
            sub: '空港からのアクセス',
          },
          imgList: [
            {
              img: {
                src: '/assets/images/about/access/airplanemenu/kv.png',
              },
              imgSp: {
                src: '/assets/images/about/access/airplanemenu/kv__sp.png',
              },
            },
          ],
        }}
      >
        <CBreadCrumb
          data={{
            parent: [
              {
                label: '当ホテルについて',
                path: '/about/',
              },
              {
                label: 'アクセス',
                path: '/about/access/',
              },
            ],
            current: {
              label: '空港からのアクセス',
            },
          }}
        />
      </CJumbotron>
      <div className="p_airplanemenu">
        <section className="u_mbExlarge l_sect">
          <LWrap>
            {/* <CFixedImg
              width={840}
              img={{
                src: '/assets/images/about/access/airplanemenu/img_airplanemenu.png',
                alt: '',
              }}
            /> */}
            <h2 className="c_headingLv2 u_mb30">羽田空港</h2>
            <CFixedImg
              exClass="u_mb20"
              width={840}
              img={{
                src: '/assets/images/about/access/airplanemenu/img_airplanemenu02.png',
                alt: '',
              }}
            />
            <ul className="c_noteList u_mb60">
              <li>
                所要時間は目安です。乗り換え、待ち時間は含まれておりません。また運行状況により多少異なります。
              </li>
              <li>
                最寄り駅は、JR・市営地下鉄「桜木町駅」または
                みなとみらい線「みなとみらい駅」です。
              </li>
            </ul>
            <div className="c_2col">
              <table className="c_fixedTable">
                {/* <colgroup>
                  <col span={1} className="col1" />
                  <col span={3} />
                </colgroup> */}
                <thead>
                  <tr>
                    <th colSpan={3}>
                      空港リムジンバス時刻表
                      <br />
                      ＜羽田空港発 → ホテル行＞
                    </th>
                  </tr>
                  <tr>
                    <th>第3ターミナル</th>
                    <th>第2ターミナル</th>
                    <th>第1ターミナル</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>8:40</td>
                    <td>8:50</td>
                    <td>8:55</td>
                  </tr>
                  <tr>
                    <td>9:40</td>
                    <td>9:50</td>
                    <td>9:55</td>
                  </tr>
                  <tr>
                    <td>
                      10:10
                      <br />
                      10:40
                    </td>
                    <td>
                      10:20
                      <br />
                      10:50
                    </td>
                    <td>
                      10:25
                      <br />
                      10:55
                    </td>
                  </tr>
                  <tr>
                    <td>
                      11:10
                      <br />
                      11:40
                    </td>
                    <td>
                      11:20
                      <br />
                      11:50
                    </td>
                    <td>
                      11:25
                      <br />
                      11:55
                    </td>
                  </tr>
                  <tr>
                    <td>
                      12:10
                      <br />
                      12:40
                    </td>
                    <td>
                      12:20
                      <br />
                      12:50
                    </td>
                    <td>
                      12:25
                      <br />
                      12:55
                    </td>
                  </tr>
                  <tr>
                    <td>
                      13:10
                      <br />
                      13:40
                    </td>
                    <td>
                      13:20
                      <br />
                      13:50
                    </td>
                    <td>
                      13:25
                      <br />
                      13:55
                    </td>
                  </tr>
                  <tr>
                    <td>
                      14:10
                      <br />
                      14:40
                    </td>
                    <td>
                      14:20
                      <br />
                      14:50
                    </td>
                    <td>
                      14:25
                      <br />
                      14:55
                    </td>
                  </tr>
                  <tr>
                    <td>
                      15:10
                      <br />
                      15:50
                    </td>
                    <td>15:20</td>
                    <td>15:25</td>
                  </tr>
                  <tr>
                    <td>
                      16:10
                      <br />
                      16:40
                    </td>
                    <td>
                      16:00
                      <br />
                      16:20
                      <br />
                      16:50
                    </td>
                    <td>
                      16:05
                      <br />
                      16:25
                      <br />
                      16:55
                    </td>
                  </tr>
                  <tr>
                    <td>17:10</td>
                    <td>17:20</td>
                    <td>17:25</td>
                  </tr>
                  <tr>
                    <td>18:10</td>
                    <td>18:20</td>
                    <td>18:25</td>
                  </tr>
                  <tr>
                    <td>19:10</td>
                    <td>19:20</td>
                    <td>19:25</td>
                  </tr>
                </tbody>
              </table>
              <table className="c_fixedTable">
                {/* <colgroup>
                  <col span={1} className="col1" />
                  <col span={2} />
                </colgroup> */}
                <thead>
                  <tr>
                    <th colSpan={2}>
                      空港リムジンバス時刻表
                      <br />
                      ＜ホテル発 → 羽田空港行＞
                    </th>
                  </tr>
                  <tr>
                    <th>平日</th>
                    <th>土・日・祝日</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>6:50</td>
                    <td>6:50</td>
                  </tr>
                  <tr>
                    <td>7:50</td>
                    <td>7:50</td>
                  </tr>
                  <tr>
                    <td>
                      8:20
                      <br />
                      8:50
                    </td>
                    <td>
                      8:30
                      <br />
                      8:50
                    </td>
                  </tr>
                  <tr>
                    <td>
                      9:20
                      <br />
                      9:50
                    </td>
                    <td>
                      9:20
                      <br />
                      9:50
                    </td>
                  </tr>
                  <tr>
                    <td>
                      10:20
                      <br />
                      10:50
                    </td>
                    <td>
                      10:20
                      <br />
                      10:50
                    </td>
                  </tr>
                  <tr>
                    <td>
                      11:20
                      <br />
                      11:50
                    </td>
                    <td>
                      11:30
                      <br />
                      11:50
                    </td>
                  </tr>
                  <tr>
                    <td>
                      12:20
                      <br />
                      12:50
                    </td>
                    <td>
                      12:20
                      <br />
                      12:50
                    </td>
                  </tr>
                  <tr>
                    <td>13:50</td>
                    <td>13:50</td>
                  </tr>
                  <tr>
                    <td>14:50</td>
                    <td>14:50</td>
                  </tr>
                  <tr>
                    <td>
                      15:20
                      <br />
                      15:50
                    </td>
                    <td>
                      15:20
                      <br />
                      15:50
                    </td>
                  </tr>
                  <tr>
                    <td>
                      16:20
                      <br />
                      16:50
                    </td>
                    <td>
                      16:30
                      <br />
                      16:50
                    </td>
                  </tr>
                  <tr>
                    <td>17:50</td>
                    <td>17:50</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <ul className="c_noteList">
              <li>
                羽田空港行は予約制です。（空席がある場合は当日でもご乗車いただけます。）
              </li>
            </ul>
            <CBtnList
              exClass="u_mb60"
              data={[
                {
                  size: 'large',
                  label: '詳細はこちら（京浜急行バス） ',
                  link: {
                    href: 'https://www.keikyu-bus.co.jp/airport/h-yamashita/',
                    blank: true,
                  },
                  icon: 'blank',
                },
              ]}
            />
            <h3 className="c_headingLv4 u_tal u_mb20">関連サイト</h3>
            <ul className="c_circleList">
              <li>
                京急線（羽田空港 ⇔ 横浜駅）
                <Link
                  to="https://www.keikyu.co.jp/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  京急電鉄
                </Link>
              </li>
            </ul>
          </LWrap>
        </section>
        <section className="u_mbExlarge l_sect">
          <LWrap>
            <h2 className="c_headingLv2 u_mb30">成田空港</h2>
            <CFixedImg
              exClass="u_mb20"
              width={840}
              img={{
                src: '/assets/images/about/access/airplanemenu/img_airplanemenu03.png',
                alt: '',
              }}
            />
            <ul className="c_noteList u_mb60">
              <li>
                所要時間は目安です。乗り換え、待ち時間は含まれておりません。また運行状況により多少異なります。
              </li>
              <li>
                最寄り駅は、JR・市営地下鉄「桜木町駅」または
                みなとみらい線「みなとみらい駅」です。
              </li>
            </ul>

            <div className="c_2col">
              <table className="c_fixedTable">
                {/* <colgroup>
                  <col span={1} className="col1" />
                  <col span={3} />
                </colgroup> */}
                <thead>
                  <tr>
                    <th colSpan={3}>
                      空港リムジンバス時刻表
                      <br />
                      ＜成田空港発 → ホテル行＞
                    </th>
                  </tr>
                  <tr>
                    <th>第3ターミナル</th>
                    <th>第2ターミナル</th>
                    <th>第1ターミナル</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>9:05</td>
                    <td>9:10</td>
                    <td>9:15</td>
                  </tr>
                  <tr>
                    <td>10:35</td>
                    <td>10:40</td>
                    <td>10:45</td>
                  </tr>
                  <tr>
                    <td>11:35</td>
                    <td>11:40</td>
                    <td>11:45</td>
                  </tr>
                  <tr>
                    <td>13:05</td>
                    <td>13:10</td>
                    <td>13:15</td>
                  </tr>
                  <tr>
                    <td>14:05</td>
                    <td>14:10</td>
                    <td>14:15</td>
                  </tr>
                  <tr>
                    <td>
                      15:05
                      <br />
                      15:30
                    </td>
                    <td>
                      15:10
                      <br />
                      15:35
                    </td>
                    <td>
                      15:15
                      <br />
                      15:40
                    </td>
                  </tr>
                  <tr>
                    <td>
                      16:00 <br />
                      16:30
                    </td>
                    <td>
                      16:05 <br />
                      16:35
                    </td>
                    <td>
                      16:10
                      <br />
                      16:40
                    </td>
                  </tr>
                  <tr>
                    <td>
                      17:00
                      <br />
                      17:30
                    </td>
                    <td>
                      17:05
                      <br />
                      17:35
                    </td>
                    <td>
                      17:10
                      <br />
                      17:40
                    </td>
                  </tr>
                  <tr>
                    <td>18:00</td>
                    <td>18:05</td>
                    <td>18:10</td>
                  </tr>
                  <tr>
                    <td>19:45</td>
                    <td>19:50</td>
                    <td>19:55</td>
                  </tr>
                  <tr>
                    <td>20:45</td>
                    <td>20:50</td>
                    <td>20:55</td>
                  </tr>
                </tbody>
              </table>
              <table className="c_fixedTable" style={{ height: '100%' }}>
                {/* <colgroup>
                  <col span={1} className="col1" />
                  <col span={1} />
                </colgroup> */}
                <thead>
                  <tr>
                    <th>
                      空港リムジンバス時刻表
                      <br />
                      ＜ホテル発 → 成田空港行＞
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>6:35</td>
                  </tr>
                  <tr>
                    <td>7:35</td>
                  </tr>
                  <tr>
                    <td>8:35</td>
                  </tr>
                  <tr>
                    <td>9:35</td>
                  </tr>
                  <tr>
                    <td>11:35</td>
                  </tr>
                  <tr>
                    <td>12:35</td>
                  </tr>
                  <tr>
                    <td>13:35</td>
                  </tr>
                  <tr>
                    <td>14:45</td>
                  </tr>
                  <tr>
                    <td>15:45</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <ul className="c_noteList">
              <li>
                成田空港行は予約制です。（空席がある場合は当日でもご乗車いただけます。）
              </li>
            </ul>
            <CBtnList
              exClass="u_mb60"
              data={[
                {
                  size: 'large',
                  label: '詳細はこちら（京浜急行バス）',
                  link: {
                    href: 'https://www.keikyu-bus.co.jp/airport/n-yokohama/',
                    blank: true,
                  },
                  icon: 'blank',
                },
              ]}
            />
            <h3 className="c_headingLv4 u_tal u_mb20">関連サイト</h3>
            <ul className="c_circleList">
              <li>
                空港リムジンバス（成田空港 ⇔ YCAT 横浜駅東口）
                <Link
                  to="https://webservice.limousinebus.co.jp/web/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Airport Limousine
                </Link>
              </li>
              <li>
                空港リムジンバス（成田空港 ⇔ YCAT 横浜駅東口）
                <Link
                  to="https://www.keiseibus.co.jp/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  京成バス
                </Link>
              </li>
              <li>
                JR成田エクスプレス（成田空港 ⇔ 横浜駅）
                <Link
                  to="https://www.jreast.co.jp/ltd_exp/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  JR東日本
                </Link>
              </li>
            </ul>
          </LWrap>
        </section>
      </div>
      <section className="u_mbMedium">
        <LWrap>
          <ul className="c_noteList">
            <li>画像は全てイメージです。</li>
          </ul>
        </LWrap>
      </section>
    </Layout>
  );
};

export default SubPage;

export const query = graphql`
  query ($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        description
      }
    }
  }
`;
